<template>
  <div class="video-container">
    <div class="media-container">
      <div class="row">
        <div class="col">
          <!-- Loading -->
          <div class="alert alert-info"
               v-if="loading">
            <span class="fa fa-spinner fa-spin"></span> Loading...
          </div>

          <div v-else-if="!loading && roomName">
            <div class="alert alert-success alert-dismissible fade show">
              Connected
              <button class="close"
                      data-dismiss="alert"
                      type="button"
                      aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div>
              <button class="btn btn-danger" @click="disconnect">
                Disconnect
              </button>
            </div>
          </div>
          <button class="btn btn-primary"
                  @click="createChat(room)"
                  v-else>
            Connect
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-8 col-md-9 pr-sm-0">
          <div class="twilio-video">
            <div id="remoteTrack"
                 :style="{height: `${mediaContainerHeight}px`}">
            </div>
            <div id="localTrack"></div>
          </div>
        </div>
        <div class="col-12 col-sm-4 col-md-3 pl-sm-0">
          <chat v-if="room"
                :channel="pnChannelName"
                :uuid="uuid"
                :height="mediaContainerHeight"
                :isSubscribed="isSubscribed"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/Event';
  // eslint-disable-next-line no-unused-vars
  import Twilio, { connect, createLocalTracks, createLocalVideoTrack }
    from 'twilio-video';
  import MyMediaMixin from '@/mixins/MyMediaMixin';
  import moment from 'moment-timezone';

  const Chat = () => import('@/components/user/broadcast/Chat');
  const Hashids = require('hashids');

  export default {
    name  : 'Video',
    props : {
      email      : String,
      educatorId : Number,
    },
    data() {
      return {
        loading       : false,
        data          : {},
        localTrack    : false,
        remoteTrack   : '',
        activeRoom    : '',
        previewTracks : '',
        identity      : '',
        roomName      : null,
        room          : null,
        uuid          : null,
        isSubscribed  : false,
        pnInstance    : 'broadcastChat',
      };
    },
    components : {
      Chat,
    },
    created() {
      const hashids = new Hashids.default('', 10);
      this.uuid = hashids.encode(this.$store.getters['user/user'].user_id);
      this.$pnGetInstance(this.pnInstance).setUUID(this.uuid);


      this.$on('show_room', (roomName) => {
        EventBus.createChat(roomName);
      });

      // When a user is about to transition away from this page,
      //  disconnect from the room, if joined.
      window.addEventListener('beforeunload', this.leaveRoomIfJoined);
    },
    computed : {
      pnChannelName() {
        return 'broadcast_' + this.room;
      },
    },
    methods : {

      /**
       * Check Reserved Slot
       */
      async checkReservedSlot() {
        const start = Date.now();
        const utc = moment.utc(start);
        return await this.$http.get('api/broadcast/check_reserved', {
          params : {
            currentUtcTime : utc.format('YYYY-MM-DD HH:mm:ss'),
            educatorId     : this.educatorId,
          },
        });
      },

      /**
       * Generate access token
       */
      async getAccessToken() {
        return await this.$http.get(`api/twilio/token`, {
          params : {
            identity : this.email,
            roomName : this.room,
          },
        });
      },

      /**
       * Trigger log events
       *
       * @param message Log Message
       */
      dispatchLog(message) {
        EventBus.$emit('new_log', message);
      },

      /**
       * Attach the Tracks to the DOM.
       *
       * @param tracks
       * @param container
       */
      attachTracks(tracks, container) {
        const that = this;
        this.$nextTick(() => {
          tracks.forEach(function (track) {
            if (track) {
              const el = container.appendChild(track.attach());
              if (el.tagName === 'VIDEO') {
                el.setAttribute('controls', 'controls');
                el.setAttribute('width', that.mediaContainerWidth);
                el.setAttribute('height', that.mediaContainerHeight);
              }
            }
          });
        });
      },

      /**
       * Attach the Participant's Tracks to the DOM.
       *
       * @param participant
       * @param container
       */
      attachParticipantTracks(participant, container) {
        const tracks = Array.from(participant.tracks.values());
        this.attachTracks(tracks, container);
      },

      /**
       * Detach the Tracks from the DOM.
       *
       * @param tracks
       */
      detachTracks(tracks) {
        tracks.forEach((track) => {
          track.detach().forEach((detachedElement) => {
            detachedElement.remove();
          });
        });
      },

      /**
       * Detach the Participant's Tracks from the DOM.
       *
       * @param participant
       */
      detachParticipantTracks(participant) {
        const tracks = Array.from(participant.tracks.values());
        this.detachTracks(tracks);
      },

      /**
       * Leave Room.
       */
      leaveRoomIfJoined() {
        if (this.activeRoom)
          this.activeRoom.disconnect();

      },

      /**
       * Create Chat
       *
       * @param roomName Chat room name
       */
      createChat(roomName) {
        this.loading = true;
        const VueThis = this;
        this.checkReservedSlot().then(res => {
          if (res.data) {
            this.getAccessToken().then((data) => {
              VueThis.roomName = null;
              const token = data.data.token;
              const connectOptions = {

                // name : roomName,
                name  : roomName,
                audio : true,
                video : { width : 400 },
              };

              // before a user enters a new room,
              // disconnect the user from they joined already
              this.leaveRoomIfJoined();

              // remove any remote track when joining a new room
              document.getElementById('remoteTrack').innerHTML = '';
              Twilio.connect(token, connectOptions).then(function (room) {
                VueThis.dispatchLog('Successfully joined a Room: ' + roomName);

                // set active toom
                VueThis.activeRoom = room;
                VueThis.roomName = roomName;
                VueThis.loading = false;

                // Attach the Tracks of the Room's Participants.
                room.participants.forEach(function (participant) {
                  const previewContainer =
                    document.getElementById('remoteTrack');
                  VueThis.attachParticipantTracks(participant,
                                                  previewContainer);
                });

                // When a Participant joins the Room, log the event.
                room.on('participantConnected', function (participant) {
                  VueThis.dispatchLog('Joining: \'' +
                    participant.identity + '\'');
                });

                // When a Participant adds a Track, attach it to the DOM.
                room.on('trackSubscribed', function (track, participant) {
                  VueThis.dispatchLog(participant.identity +
                    ' added track: ' + track.kind);
                  const previewContainer =
                    document.getElementById('remoteTrack');
                  VueThis.attachTracks([track], previewContainer);
                });

                // When a Participant removes a Track, detach it from the DOM.
                room.on('trackUnsubscribed', function (track, participant) {
                  VueThis.dispatchLog(participant.identity +
                    ' removed track: ' + track.kind);
                  VueThis.detachTracks([track]);
                });

                // When a Participant leaves the Room, detach its Tracks.
                room.on('participantDisconnected', function (participant) {
                  VueThis.dispatchLog('Participant \'' + participant.identity +
                    '\' left the room');
                  VueThis.detachParticipantTracks(participant);
                });

                // if local preview is not active, create it
                if (!VueThis.localTrack) {
                  createLocalVideoTrack().then(track => {
                    const localMediaContainer =
                      document.getElementById('localTrack');
                    localMediaContainer.appendChild(track.attach());
                    VueThis.localTrack = true;
                  });
                }

                VueThis.$pnSubscribe({
                  channels     : [VueThis.pnChannelName],
                  withPresence : true,
                }, VueThis.pnInstance);
                VueThis.isSubscribed = true;

              });
            });

          } else {
            // let that = this;
            this.loading = false;

            this.$modal.show('dialog', {
              title : 'Ooops! Something went wrong.',
              text  : 'It\'s not time yet! Are you sure you have ' +
                'a reserved slot?',
              buttons : [{
                title   : 'CLOSE',
                handler : () => {
                  this.$modal.hide('dialog');
                },
              }],
            });
          }
        });

      },

      /**
       * Disconnect Broadcast
       */
      disconnect() {
        this.$pnUnsubscribe({
          channels : [this.pnChannelName],
        }, this.pnInstance);
        this.$pnGetInstance(this.pnInstance).deleteMessages({
          channels : [this.pnChannelName],
        });
        this.isSubscribed = false;
        this.leaveRoomIfJoined();
        this.$router.go(this.$router.currentRoute); // temp solution
      },

    },
    mounted() {
      this.room = this.$route.params.roomName;
      this.checkReservedSlot();
    },
    mixins : [
      MyMediaMixin,
    ],
  };
</script>

<style lang="scss">

</style>
